import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What we do`}</h2>
    <ProjectCard title="Development" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Software development with a focus on quality and maintainability.
    </ProjectCard>
    <ProjectCard title="Design and Architecture" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Simple but elegant design and architecture for complex business requirements.
    </ProjectCard>
    <ProjectCard title="Testing" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
Effective testing at all levels from Unit to Acceptancence tests making the rollout of new features a breeze.
    </ProjectCard>
    <ProjectCard title="DevOps" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
Automating build, testing, and deployment to implement continuous delivery allowing rapid business change.
    </ProjectCard>
    <ProjectCard title="Cloud" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Design and implement cost-effective and secure cloud-native solutions.
    </ProjectCard>
    <ProjectCard title="Security" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Security at every level from code to design to infrastructure.
    </ProjectCard>
    <ProjectCard title="Training" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
Improving software engineering practices within teams organically with pair programming and code reviews.
    </ProjectCard>
    <ProjectCard title="NoSQL" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
Navigating the complex world of NoSQL databases with experience of Relational, Document, Graph, Column, and Key-Value databases. 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      