import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="iommicLogo.svg" alt="iommic" width="100%" height="auto" />
    <p>{`Iommic Ltd is passionate about delivering beautiful software for our customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      